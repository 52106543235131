import { apiService } from '@/api/api'
import DocAPI from '@/api/apiDoc'
import { mapTransportationDTOToFreight } from '@/api/mappers/freight'
import { setDateToEnd, setDateToStart } from '@/utils/helpers'
import { formatDate } from '@/utils/helpersLegacy'
import { defineStore } from 'pinia'
import { useAppStore } from './app'
import { useSnackbarStore } from './snackbar'
import { useVehiclesStore } from './vehicles'
import { useDriversStore } from './drivers'

export const useFreightStore = defineStore('freight', {
  state: () => ({
    localeDateTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    loading: false,
    history: [],
    data: [],
    delays: [],
    filterByPartner: [],
    filterByFreightStatus: [],
    filterByEnd: null,
    filterByStart: null,
    filterById: null,
    reportStartDate: setDateToStart(new Date()),
    reportEndDate: setDateToEnd(new Date()),
    currentPage: 1,
    minDate: new Date(2015, 0, 1),
    detailedFreight: undefined,
    totalCount: 0,
    searchParams: {
      page: 0,
      itemsPerPage: 50,
      sort: 'DATE_START_ASC',
      phrase: '',
      isLate: false
    },
    driverActionsModal: {
      isOpen: false,
      freight: null
    }
  }),
  getters: {
    isFiltered(state) {
      let count = 0
      count += state.filterByPartner.length > 0 ? 1 : 0
      count += state.filterByFreightStatus.length > 0 ? 1 : 0
      count += state.filterByEnd ? 1 : 0
      count += state.filterByStart ? 1 : 0
      count += state.filterById ? 1 : 0
      return count
    }
  },
  actions: {
    async reset() {
      this.$reset()
    },
    async fetchDetailedFreight(id) {
      const freight = await apiService.getTransporationDetailed(id)
      this.detailedFreight = freight
    },
    async showTransportationDetails(id) {
      if (this.detailedFreight !== undefined) {
        const snackbarStore = useSnackbarStore()
        snackbarStore.openSnackbar({
          color: 'secondary',
          message: 'Карточка грузоперевозки уже открыта'
        })
        return
      }
      await this.fetchDetailedFreight(id)
      const appStore = useAppStore()
      appStore.openFreightDetails()
    },
    closeTransportationDetails() {
      const appStore = useAppStore()
      appStore.closeFreightDetails()

      // prevent modal from jumping
      setTimeout(() => {
        this.detailedFreight = undefined
      }, 300)
    },
    async downloadReport() {
      try {
        const link = await DocAPI.post(
          `transportations/report`,
          {
            dateFrom: this.reportStartDate || formatDate(0),
            dateTo: this.reportEndDate || formatDate(Date.now())
          },
          { responseType: 'blob' }
        )
        let report_name = 'Отчет'
        report_name += this.reportStartDate ? `_с_${formatDate(this.reportStartDate)}` : ''
        report_name += this.reportEndDate ? `_по_${formatDate(this.reportEndDate)}` : ''
        report_name += '.xlsx'
        link.download = report_name
        document.body.appendChild(link)
        link.click()
        return true
      } catch (e) {
        return false
      }
    },
    async downloadFreightDetails() {
      try {
        const link = await DocAPI.get(
          `transportations/${this.detailedFreight.id}/report`,

          { responseType: 'blob' }
        )
        let report_name = `transportation_${this.detailedFreight.containerNumber}`
        report_name += '.xlsx'
        link.download = report_name
        document.body.appendChild(link)
        link.click()
        return true
      } catch (e) {
        return false
      }
    },

    async takeOffDriver({ transportationId, driverId, vehicleId }) {
      const response = await apiService.cancelDriver(transportationId, driverId)
      this.data = this.data.map((freight) => {
        if (freight.id === response.id) return mapTransportationDTOToFreight(response)
        return freight
      })

      const appStore = useAppStore()
      const vehiclesStore = useVehiclesStore()
      const driversStore = useDriversStore()
      await Promise.all([
        appStore.refreshDetails(),
        driversStore.refetchDriver(driverId),
        vehiclesStore.refetchVehicle(vehicleId)
      ])
    },
    async assignDriver({ transportationId, driverId, vehicleId }) {
      const response = await apiService.assignDriver(transportationId, driverId, vehicleId)
      this.data = this.data.map((freight) => {
        if (freight.id === response.id) return mapTransportationDTOToFreight(response)
        return freight
      })

      const appStore = useAppStore()
      const vehiclesStore = useVehiclesStore()
      const driversStore = useDriversStore()
      await Promise.all([
        appStore.refreshDetails(),
        driversStore.refetchDriver(driverId),
        vehiclesStore.refetchVehicle(vehicleId)
      ])
    },
    async includeInSpreading(transportationId) {
      await apiService.includeTransportationInSpreading(transportationId)

      await this.fetchDetailedFreight(this.detailedFreight.id)
    },
    async excludeFromSpreading(transportationId) {
      await apiService.excludeTransportationFromSpreading(transportationId)

      await this.fetchDetailedFreight(this.detailedFreight.id)
    },
    async approveDriver(transportationId, driverId, vehicleId) {
      const response = await apiService.approveDriver(transportationId)
      this.data = this.data.map((freight) => {
        if (freight.id === response.id) return mapTransportationDTOToFreight(response)
        return freight
      })

      const appStore = useAppStore()
      const vehiclesStore = useVehiclesStore()
      const driversStore = useDriversStore()
      await Promise.all([
        appStore.refreshDetails(),
        driversStore.refetchDriver(driverId),
        vehiclesStore.refetchVehicle(vehicleId)
      ])
    },
    async rejectDriver(transportationId, driverId, vehicleId) {
      const response = await apiService.rejectDriver(transportationId)
      this.data = this.data.map((freight) => {
        if (freight.id === response.id) return mapTransportationDTOToFreight(response)
        return freight
      })
      const appStore = useAppStore()
      const vehiclesStore = useVehiclesStore()
      const driversStore = useDriversStore()
      await Promise.all([
        appStore.refreshDetails(),
        driversStore.refetchDriver(driverId),
        vehiclesStore.refetchVehicle(vehicleId)
      ])
    },
    setSearchParams(page, itemsPerPage, sortBy, search, isLate = false) {
      this.searchParams.page = page
      this.searchParams.itemsPerPage = itemsPerPage
      if (sortBy.length === 0) {
        this.searchParams.sort = 'DATE_START_ASC'
      } else {
        this.searchParams.sort = sortBy[0].order === 'desc' ? 'DATE_START_DESC' : 'DATE_START_ASC'
      }
      this.searchParams.phrase = search
      this.searchParams.isLate = isLate
    },
    async loadFreights() {
      this.loading = true
      const response = await apiService.searchTransportations({
        ...this.searchParams,
        fromDate: this.filterByStart,
        toDate: this.filterByEnd,
        partnerIds: this.filterByPartner.map((partner) => partner.id),
        statuses: this.filterByFreightStatus
      })
      this.data = response.items.map(mapTransportationDTOToFreight)
      this.totalCount = response.totalCount
      this.loading = false
    },
    RESET() {
      this.$reset()
    },
    SET_DATA(value) {
      this.data = value ? value : []
    },
    HISTORY(value) {
      this.history = value ? value : []
    },
    FILTER_BY_PARTNER(value) {
      this.filterByPartner = value
      this.loadFreights()
      this.currentPage = 1
    },
    setFilterByStart(value) {
      this.filterByStart = value
      // report should also initialise this date with the same value
      this.reportStartDate = value
      this.loadFreights()
      this.currentPage = 1
    },
    setFilterByEnd(value) {
      this.filterByEnd = value
      // report should also initialise this date with the same value
      this.reportEndDate = value
      this.loadFreights()
      this.currentPage = 1
    },
    FILTER_BY_FREIGHT_STATUS(value) {
      this.filterByFreightStatus = value
      this.loadFreights()
      this.currentPage = 1
    },
    setFilterById(value) {
      this.filterById = value
    },
    CLEAR_FILTER() {
      this.filterByPartner = []
      this.filterByFreightStatus = []
      this.filterByEnd = null
      this.filterByStart = null
      this.filterById = null
      this.reportStartDate = null
      this.reportEndDate = null
      this.loadFreights()
      this.currentPage = 1
    },
    setLoadingState(value) {
      this.loading = value
    },
    setReportStartDate(value) {
      this.reportStartDate = value
    },
    setReportEndDate(value) {
      this.reportEndDate = value
    },
    setDriverActionsModalState({ isOpen, freightData }) {
      if (freightData === null) {
        this.driverActionsModal.isOpen = false
        return
      }
      if (freightData) {
        this.driverActionsModal.freight = freightData
        this.driverActionsModal.isOpen = isOpen
        return
      }
    }
  }
})
